import AlphaFilters from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-filters'

let sunSvgEl = null
AlphaFilters.addAction('svgUniqId.element.parsed', (svgEl) => {
  if (svgEl.getAttribute('data-name') === 'filigrane-sun') {
    sunSvgEl = svgEl
  }
})

document.addEventListener('alpha.loaded', (e) => {
  // get module manager instance
  const moduleMgr = e.detail.emitter
  moduleMgr.addModuleEventListener('gsapWrapper', 'gsap.plugins.loaded', (ev) => {
    // get GSAP instance when all plugins are loaded
    const gsap = ev.gsap

    // do something

    const ScrollTrigger = ev.emitter.getGsapPlugin('ScrollTrigger')

    // Text forms from shadow
    const elements = document.querySelectorAll('.animate-text-shadow-wrapper')
    elements.forEach((element) => animateTextShadow(element))

    function animateTextShadow(element) {
      const elements = element.getElementsByClassName('animate-text-shadow')

      ScrollTrigger.create({
        trigger: element,
        start: 'top 100%', // when the top of the trigger hits 50% of the way through the view port (i.e. 50% down the screen)
        onEnter: () => {
          // Text forms from shadow
          gsap.to(elements, {
            opacity: 1,
            scale: 1,
            ease: 'elastic.out(1.2, 0.2)',
            stagger: 0.7
          })
          const tl = gsap.timeline()
          tl.set(elements, {
            opacity: 1,
            perspective: 400
          }, 'start')
          tl.fromTo(elements, {
            textShadow: '0px 0px 30px #FFF',
            color: 'rgba(0,0,0,0)',
            duration: 0.5,
            stagger: 0.1
          },
          {
            textShadow: '0px 0px 20px #000',
            color: 'rgba(0,0,0,0)',
            duration: 1.5,
            ease: 'power2.in',
            stagger: 0.1
          },
          'start')
            .to(elements,
              {
                textShadow: '0px 0px 0px #000',
                duration: 0.5,
                ease: 'power2.Out',
                stagger: 0.1
              }, 'start+=1')
            .to(elements,
              {
                color: 'rgba(0,0,0,1)',
                duration: 0.5,
                ease: 'power2.inOut',
                stagger: 0.1
              }, 'start+=1.5')
        }
      })
    }

    if (sunSvgEl) {
      setTimeout(() => {
        gsap.from('[data-name="filigrane-sun"]', {
          scrollTrigger: {
            trigger: '[data-name="filigrane-sun"]',
            start: 'top bottom', // the default values
            end: 'bottom top',
            toggleClass: 'active',
            scrub: 1
          }
        })
      }, 10)
    }
    setTimeout(() => {
      gsap.from('[data-name="filigrane-logo"]', {
        scrollTrigger: {
          trigger: '[data-name="filigrane-logo"]',
          start: 'top bottom', // the default values
          end: 'bottom top',
          toggleClass: 'active',
          scrub: 1
        }
      })
    }, 10)
  })
})
